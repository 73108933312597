/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { sha256 } from 'js-sha256';

import {
    UPDATE_PRODUCT_LIST_INFO,
    updateProductListInfo
} from 'Store/ProductListInfo/ProductListInfo.action';

import { getCustomerData } from '../../data/customer';
import { handleProductFilterEvents } from '../../event/filter';

const addProductFilterEventHandler = (args, callback) => {
    const [state, action] = args;

    if (action.type !== UPDATE_PRODUCT_LIST_INFO) {
        return callback(...args);
    }

    const { selectedFilter: { customFilters: oldCustomFilters = {} } = {} } = state;
    const {
        selectedFilter: { customFilters },
        products: { filters: filtersDetails },
        customerId
    } = action;

    handleProductFilterEvents(oldCustomFilters, customFilters, filtersDetails, customerId);

    return callback(...args);
};

const aroundOnSuccess = async (functionArgs) => {
    const [{ products }, dispatch, options] = functionArgs;
    const {
        args: {
            filter
        }
    } = options;
    const {
        customerId, customerEmail, userExistingCustomer, userLifetimeOrders, userLifetimeValue
    } = await getCustomerData();

    dispatch(
        {
            ...updateProductListInfo(products, filter),
            customerId,
            customerEmail,
            customerHashedEmail: (customerEmail !== 'N/A') ? sha256(customerEmail) : 'N/A',
            userExistingCustomer,
            userLifetimeOrders,
            userLifetimeValue
        }
    );
};

export default {
    'Store/ProductListInfo/Reducer/ProductListReducer': {
        function: addProductFilterEventHandler
    },
    'Store/ProductListInfo/Dispatcher': {
        'member-function': {
            onSuccess: aroundOnSuccess
        }
    }
};
