/* Override sidea */
import PropTypes from 'prop-types';

import {
    Field as SourceField
} from 'SourceComponent/Field/Field.component';
import { MixType } from 'Type/Common.type';
import {
    EventsType,
    FieldAttrType,
    LabelType,
    OptionType
} from 'Type/Field.type';

import { FIELD_TYPE } from './Field.config';

import './Field.override.style';

/**
 * @namespace Frontend/Component/Field/Component
 */
export class Field extends SourceField {
    static propTypes = {
        type: PropTypes.oneOf(Object.values(FIELD_TYPE)).isRequired,
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        setRef: PropTypes.func.isRequired,
        mix: MixType.isRequired,
        options: PropTypes.arrayOf(OptionType).isRequired,
        changeValueOnDoubleClick: PropTypes.bool,
        isSortSelect: PropTypes.bool,
        resetFieldValue: PropTypes.func.isRequired,
        value: PropTypes.number,
        validate: PropTypes.func.isRequired,
        showErrorAsLabel: PropTypes.bool.isRequired,
        validationResponse: (props, propName, componentName) => {
            const propValue = props[propName];

            if (propValue === null) {
                return;
            }

            if (typeof propValue === 'boolean') {
                return;
            }

            if (typeof propValue === 'object' && !Object.keys(propValue).includes('errorMessages')) {
                throw new Error(
                    `${componentName} only accepts null, bool or object of "errorMessages" as "validationResponse", received "${JSON.stringify(propValue)}"`
                );
            }
        },
        label: LabelType.isRequired,
        subLabel: PropTypes.string.isRequired,
        addRequiredTag: PropTypes.bool.isRequired
    };

    static defaultProps = {
        validationResponse: null,
        changeValueOnDoubleClick: false,
        isSortSelect: false,
        value: 1
    };
}

export default Field;
