/* Override Sidea */

import PropTypes from 'prop-types';

import {
    ContentWrapper as SourceContentWrapper
} from 'SourceComponent/ContentWrapper/ContentWrapper.component';
import { ChildrenType, MixType } from 'Type/Common.type';
import { LabelType } from 'Type/Field.type';

import './ContentWrapper.override.style';

/** @namespace Frontend/Component/ContentWrapper/Component */
export class ContentWrapper extends SourceContentWrapper {
    static propTypes = {
        children: ChildrenType,
        mix: MixType,
        wrapperMix: PropTypes.shape({
            block: PropTypes.string,
            elem: PropTypes.string
        }),
        label: LabelType,
        isNotSection: PropTypes.bool
    };

    static defaultProps = {
        mix: {},
        wrapperMix: {},
        children: null,
        isNotSection: false,
        label: ''
    };
}

export default ContentWrapper;
