import {
    BILLING_STEP as SOURCE_BILLING_STEP,
    BILLING_URL as SOURCE_BILLING_URL,
    BILLING_URL_STEP as SOURCE_BILLING_URL_STEP,
    CHECKOUT_URL as SOURCE_CHECKOUT_URL,
    CHECKOUT_URL_REGEX as SOURCE_CHECKOUT_URL_REGEX,
    DETAILS_STEP as SOURCE_DETAILS_STEP,
    DETAILS_URL_STEP as SOURCE_DETAILS_URL_STEP,
    PAYMENT_TOTALS as SOURCE_PAYMENT_TOTALS,
    SHIPPING_STEP as SOURCE_SHIPPING_STEP,
    SHIPPING_URL as SOURCE_SHIPPING_URL,
    SHIPPING_URL_STEP as SOURCE_SHIPPING_URL_STEP,
    UPDATE_EMAIL_CHECK_FREQUENCY as SOURCE_UPDATE_EMAIL_CHECK_FREQUENCY
} from 'SourceRoute/Checkout/Checkout.config';

// TODO: implement SHIPPING_STEP
export const SHIPPING_STEP = SOURCE_SHIPPING_STEP;

// TODO: implement BILLING_STEP
export const BILLING_STEP = SOURCE_BILLING_STEP;

// TODO: implement DETAILS_STEP
export const DETAILS_STEP = SOURCE_DETAILS_STEP;

// TODO: implement BILLING_URL_STEP
export const BILLING_URL_STEP = SOURCE_BILLING_URL_STEP;

// TODO: implement SHIPPING_URL_STEP
export const SHIPPING_URL_STEP = SOURCE_SHIPPING_URL_STEP;

// TODO: implement DETAILS_URL_STEP
export const DETAILS_URL_STEP = SOURCE_DETAILS_URL_STEP;

// TODO: implement CHECKOUT_URL
export const CHECKOUT_URL = SOURCE_CHECKOUT_URL;

// TODO: implement BILLING_URL
export const BILLING_URL = SOURCE_BILLING_URL;

// TODO: implement SHIPPING_URL
export const SHIPPING_URL = SOURCE_SHIPPING_URL;

// TODO: implement CHECKOUT_URL_REGEX
export const CHECKOUT_URL_REGEX = SOURCE_CHECKOUT_URL_REGEX;

// TODO: implement PAYMENT_TOTALS
export const PAYMENT_TOTALS = SOURCE_PAYMENT_TOTALS;

// TODO: implement UPDATE_EMAIL_CHECK_FREQUENCY
export const UPDATE_EMAIL_CHECK_FREQUENCY = SOURCE_UPDATE_EMAIL_CHECK_FREQUENCY;
