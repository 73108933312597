/* Override sidea */
import PropTypes from 'prop-types';

import {
    FieldContainer as SourceFieldContainer
} from 'SourceComponent/Field/Field.container';

/**
 * @namespace Frontend/Component/Field/Container
 */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...SourceFieldContainer.propTypes,
        value: PropTypes.number
    };

    static defaultProps = {
        ...SourceFieldContainer.defaultProps,
        value: 1
    };
}

export default FieldContainer;
