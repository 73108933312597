import {
    App as SourceApp
} from 'SourceComponent/App/App.component';

/** @namespace Frontend/Component/App/Component */
export class App extends SourceApp {
    render() {
        const conveythisScript = document.createElement('script');
        conveythisScript.src = '//cdn.conveythis.com/javascript/conveythis-initializer.js';

        const conveythisConfig = document.createElement('script');
        conveythisConfig.innerHTML = 'document.addEventListener("DOMContentLoaded", function(e) { ConveyThis_Initializer.init({ api_key: "pub_b3bc782c860e8160be5d7290e2377706" }); });';

        const head = document.getElementsByTagName('head')[0];
        head.appendChild(conveythisScript);
        head.appendChild(conveythisConfig);

        return this.renderContextProviders();
    }
}

export default App;
